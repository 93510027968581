$button-radius: 8px;
$bg-gray: #f6faff;
$primary-color: #5061dc;
$secondary-color: rgba(37, 54, 171, 0.5);

@import-normalize;
@import "~antd/dist/antd.css";
/* @import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;700&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

*,
body {
  font-family: "Rubik", sans-serif;
}

#root,
.ant-spin-nested-loading,
.ant-spin-container,
.ant-layout {
  height: 100%;
}
#root .ant-spin-spinning {
  position: absolute;
  width: 100%;
  top: 50%;
  font-weight: bold;
}

a,
.ant-btn-link {
  color: $primary-color;
  &:hover {
    color: $secondary-color;
  }
  &:disabled {
    color: rgba(0, 0, 0, 0.25) !important;
  }
}

.ant-layout {
  background-color: #fff;
}

.ant-layout-content {
  min-height: auto;
}

.ant-layout-footer {
  margin-top: 2rem;
}

.ant-tooltip-inner {
  border-radius: 12px;
}

.ant-btn {
  border-radius: $button-radius;
  font-weight: bold;
  font-size: 16px;
}

.ant-btn-primary {
  background: $primary-color;
  box-shadow: 0 16px 20px -10px rgba(37, 54, 171, 0.5);
  border-color: $primary-color;
  color: #fff;
  &:disabled {
    background: #dae1f2 !important;
    color: #ffffff;
    border-color: transparent;
    &:hover {
      color: #fff;
    }
  }
}

.ant-btn-default {
  border: 1px solid $primary-color;
  color: $primary-color;
  &:hover {
    color: rgba(37, 54, 171, 0.5);
    border: 1px solid rgba(37, 54, 171, 0.5);
  }
}

.ant-btn-lg {
  height: 48px;
}

.ant-input {
  border-radius: 8px;
}

.ant-input-lg {
  padding: 12px 22px;
}

.ant-layout-footer {
  background-color: transparent;
  color: #98a9bc;
  padding: 0;
  .ant-col {
    padding: 16px 0;
    border-top: 1px solid #dae1f2;
    p {
      margin-bottom: 0;
    }
    &:last-child {
      text-align: right;
    }
  }
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: 0;
}

.ant-popover-inner {
  border-radius: 12px;
  .ant-popover-title {
    color: #98a9bc;
    padding: 10px 16px;
  }
  .ant-popover-inner-content {
    padding: 2px 16px;
  }
}

.ant-table {
  background-color: transparent;
  .ant-table-thead tr {
    margin-bottom: 10px;
    .ant-table-cell {
      color: #3a4a61;
      font-size: 14px;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 0 16px;
      font-weight: 500;
      &::before {
        display: none;
      }
    }
  }
  .ant-table-tbody {
    .ant-table-row {
      border: 1px solid #ededed;
      box-shadow: 0 3px 26px -5px rgb(33 31 31 / 10%);
      border-radius: 10px;
      box-sizing: border-box;
      margin-bottom: 10px;
      max-width: 100%;
      overflow: hidden;
      background: #fff;
      .ant-table-cell {
        border: 0;
        display: flex;
        align-items: center;
        .ant-select {
          height: auto !important;
        }
        .ant-input {
          height: auto !important;
          &:focus {
            background: #faf9f7;
            border: 1px solid #e0dbd1;
            border-radius: 4px;
          }
        }
      }
    }
  }
}

.ant-tabs-tab {
  .ant-tabs-tab-btn {
    color: #c1cce7;
  }
}

.ant-tabs-tab-active.tab-task {
  .ant-tabs-tab-btn {
    color: #21d996 !important;
  }
}
.ant-tabs-tab-active.tab-instrument {
  .ant-tabs-tab-btn {
    color: #ff6b86 !important;
  }
}

.task-ink-bar {
  .ant-tabs-ink-bar {
    background: #21d996;
  }
}

.instrument-ink-bar {
  .ant-tabs-ink-bar {
    background: #ff6b86;
  }
}

.ant-upload-text {
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    color: #5061dc;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    margin-left: 5px;
  }
}

.ant-upload-hint {
  color: #98a4bc !important;
  font-size: 12px !important;
}

.ant-upload-count {
  text-align: center;
  color: #5b6673;
  font-size: 14px;
}

@import "content";
@import "audio-interview-settings";
