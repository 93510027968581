.audio-interview-settings {
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  gap: 0 45px;
  grid-template-areas: ". .";
  &__col {
    &-title {
      text-transform: uppercase;
      color: #98a4bc;
      font-size: 18px;
      line-height: 24px;
      font-weight: 500;
      &_second {
        margin-top: 35px;
      }
      &_s {
        text-transform: inherit;
      }
    }
  }
  &__limit {
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr 45px;
    grid-template-rows: auto;
    gap: 0 15px;
    grid-template-areas: ". .";
    & + & {
      margin-top: 10px;
    }
    & span {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #5b6673;
      padding-top: 8px;
    }
    & input {
      border: 1px solid #dae1f2;
      box-sizing: border-box;
      border-radius: 8px;
      height: 35px;
      text-align: center;
      font-weight: 600;
      font-size: 16px;
      line-height: 23px;
      color: #5061dc;
      outline: none;
      &:focus {
        border-color: #8564ff;
      }
    }
  }
  &__skills {
    &-top {
      display: grid;
      grid-auto-columns: 1fr;
      grid-template-columns: repeat(auto-fit, minmax(1px, 1fr));
      grid-template-rows: 22px;
      gap: 0 3px;
    }
    &-line {
      border: 1px solid #8564ff;
      box-sizing: border-box;
      border-radius: 2px;
      &_active {
        background: #8564ff;
      }
    }
    &-bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      padding-top: 5px;
    }
    &-title {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #d8cdff;
      margin-top: 15px;
      cursor: pointer;
      &_active {
        color: #8564ff;
        position: relative;
        &::before,
        &::after {
          content: "";
          position: absolute;
          left: 50%;
          top: -24px;
          margin-left: -11px;
          border: 11px solid transparent;
          border-bottom: 11px solid #8564ff;
        }
        &::after {
          border: 7px solid transparent;
          border-bottom: 7px solid #fff;
          top: -18px;
          margin-left: -7px;
        }
      }
    }
  }
  &__info {
    background-image: url("../assets/lamp.svg");
    background-repeat: no-repeat;
    background-position: 15px 15px;
    background-size: 24px;
    border: 1px solid #c1cce7;
    box-sizing: border-box;
    border-radius: 12px;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #5b6673;
    margin-top: 25px;
    padding: 16px 16px 16px 55px;
    & p {
      margin-bottom: 0;
    }
  }
  &__new-audio {
    margin-top: 25px;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
  }
  &__inline {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 16px;
    width: 100%;
  }
  &__send-email {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    & span {
      color: #5061dc;
    }
  }
  &__resume-list {
    color: #5061dc;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-transform: uppercase;
    & svg {
      margin-left: 3px;
      display: inline-block;
      vertical-align: top;
      transition: 0.3s;
    }
    &:hover svg {
      opacity: 0.7;
    }
  }
  &__subject {
    margin-top: 24px;
  }
  &__body {
    margin-top: 24px;
  }
}
