.content {
  &__body {
    background: #f7faff;
    box-shadow: 0px 24px 40px -15px rgba(92, 102, 115, 0.3);
    border-radius: 12px;
    padding: 12px 32px 12px 32px;
    margin-bottom: 30px;
    &-title {
      padding-left: 0;
      border-radius: 12px;
      .ant-page-header-heading-title {
        color: #98a4bc;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
      }
      .ant-page-header-back {
        margin-right: 8px;
        .ant-page-header-back-button {
          cursor: default;
        }
      }
    }
  }
}

.vacancies {
  .ant-btn,
  .anticon-cloud-download {
    color: #5061dc;
  }
  .ant-btn-default.ant-btn-sm {
    border-radius: $button-radius;
  }
}
